export const systemConfig = {
    // Saturn
    systemName: 'Mercury',                  // Used for Titles and labels only
    apiRefrenceSystemName: 'Mercury',       // Used as Valid API refrence system name
    reportServerName: process.env.REACT_APP_REPORTSERVER,     // Used for reports server URL
    helpSiteName: process.env.REACT_APP_HELPSITE, // Used to point Help Page to appropriate Sharepoint site
    docCreationSystemName: 'Mercury',       // Used for creating document with dynamic name
    systemVersion: '4.1.123'
};

export const navbarItems = [];