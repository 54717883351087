
import React, { useEffect, useState } from 'react';

// @ts-ignore
import { FetchAPIPromise, PostAPIPromise, formatComponentPageName } from '../BaseComponent.js';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { Column, MasterDetail, Lookup, Editing, FormItem, RequiredRule, DataGridTypes, DataGridRef } from 'devextreme-react/data-grid';
import Form, { Item, EmptyItem, Label } from 'devextreme-react/form';

import moment from 'moment';
import Button from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import * as $ from 'jquery';

import { AirportDTO } from '../../types/airport-dto.js';
import { DictFlightIssue } from '../../types/dict-flight-issue.js';
import { CharterItineraryDTO } from '../../types/charter-itinerary-dto.js';
import { CharterSegmentDTO } from '../../types/charter-segment-dto.js';
import { CharterBookingsFilter } from '../../types/charter-bookings-filter.js';

// ABG: Don't love this, but, it is historically what we do in cases like this
let flightIssues: Array<DictFlightIssue> = [];
let airports: Array<AirportDTO> = [];

const statuses = [{ name: 'Open', code: 'Open' }, { name: 'Active', code: 'Active' }, { name: 'Completed', code: 'Completed' }, { name: 'Rescheduled', code: 'Rescheduled' }, { name: 'Cancelled', code: 'Cancelled' }];

function OpsCoordinatorCharterFlightInformation() {

    formatComponentPageName('Charter Bookings');

    const [isViewingCharterCarriers, setIsViewingCharterCarriers] = useState(false);

    const [filters] = useState<CharterBookingsFilter>({ statusCodes: [] });

    const datagridRef = React.useRef<DataGridRef>(null);

    useEffect(() => {
        async function fetchData() {
            const airportListData = await FetchAPIPromise('Facility/GetAirports');

            const flightIssueData = await FetchAPIPromise('DictionaryFlightIssue');

            flightIssues = flightIssueData;
            airports = airportListData;
        }

        fetchData();
    }, []);

    const charterCarrierStore = new CustomStore({
        key: 'code',
        load: () => {
            return FetchAPIPromise('DictionaryCharterCarrier');
        },
        insert: (values) => {
            return PostAPIPromise('DictionaryCharterCarrier', values);
        },
        update: (key, values) => {
            return PostAPIPromise('DictionaryCharterCarrier', values);
        },
        byKey: function (key) {
            // console.log(key);
            return FetchAPIPromise('DictionaryCharterCarrier/' + key);
        }  
    });

    var lkupDataSourceConfiguration = {
        store: charterCarrierStore
    };  

    const onRowUpdating = (options: DataGridTypes.RowUpdatingEvent) => {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    const charterFlightStore = new CustomStore({
        key: 'itineraryId',
        load: () => {

            console.log('Incoming filters', filters);

            return PostAPIPromise('CharterBookings/GetAll', filters.statusCodes).then((response: Response) => {
                // console.log(response);
                return response;
            });
        },
        insert: (values) => {
            return PostAPIPromise('CharterBookings', values);
        },
        update: (key, values) => {
            return PostAPIPromise('CharterBookings', values);
        }
    });

    const dayOfWeekRender = (data: CharterItineraryDTO) => {
        if (data.requestDate) {
            return moment(data.requestDate).format('dddd');
        }
    }

    const charterTypeRender = (data: CharterItineraryDTO) => {

        // Basically, if all of the country codes are US, it's domestic. If the country codes start in US, it's international
        //console.log(cell.row.data.flights);
        let charterType = 'Pending';

        if (data.flights) {
            data.flights.map((item) => {
                if (item.departureAirportCountryCode == '5189' && item.arrivalAirportCountryCode == '5189') {
                    charterType = 'Domestic';
                }
                else {
                    charterType = 'International';
                }
            });
        }

        return charterType;
    }

    const departureDayScheduledRender = (data: CharterItineraryDTO) => {
        return moment(data.scheduledDepartureDate).format('dddd');
    }

    const flightTotalHoursRender = (data: CharterItineraryDTO) => {
        // TODO: Should use UTC?
        var totalHours = 0;

        var firstDeparture: Date | null = null;
        var lastArrival = null;

        if (data.flights) {
            data.flights.length > 0 && data.flights.sort((a, b) => moment(a.departureUTCTime).valueOf() - moment(b.departureUTCTime).valueOf()).map((item) => {
                if (!firstDeparture) {
                    firstDeparture = item.actualDepartureUTCTime;
                }

                lastArrival = item.actualArrivalUTCTime;
            });

            const now = moment();
            if (firstDeparture && lastArrival) {
                //console.log(firstDeparture);
                //console.log(lastArrival);
                totalHours += moment(lastArrival).diff(moment(firstDeparture), 'minutes');

                return (Math.round(totalHours / 15) * 15) / 60;
            }
        }
    }

    const showCharterCarriers = () => {
        setIsViewingCharterCarriers(true);
    }

    const hideCharterCarriers = () => {
        setIsViewingCharterCarriers(false);
    }

    const filterDataChanged = () => {
        datagridRef.current?.instance().refresh();
    }

    const preFlightOptions = [{ name: 'Yes', code: true }, { name: 'No', code: false }];

    return (
        <div className="container-fluid">
            <h1>Charter Bookings</h1>
            <br />

            <div className="d-flex justify-content-between">
                <div className="ms-auto">
                    <Button text="Manage Charter Carriers" onClick={showCharterCarriers} />
                </div>
            </div>
            <br />

            <Form
                formData={filters} colCount={3} onFieldDataChanged={filterDataChanged}>
                <EmptyItem colSpan={2} />
                <Item editorType="dxTagBox" dataField="statusCodes" editorOptions={{ searchEnabled: true, applyValueMode: 'useButtons', showSelectionControls: true, dataSource: statuses, displayExpr: 'name', valueExpr: 'code' }}>
                    <Label text="Status(es)" />
                </Item>
            </Form>
            <br />

            <DataGrid ref={datagridRef} dataSource={charterFlightStore} showBorders={true} allowColumnResizing={true} onRowUpdating={onRowUpdating}>
                <Editing allowAdding={true} mode="popup" allowUpdating={true} />

                <Column dataField="scheduledDepartureDate" caption="Departure Date Scheduled" dataType="date" format="MM/dd/yyyy">
                    <RequiredRule />
                </Column>
                <Column caption="Day Scheduled" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={departureDayScheduledRender}>
                    <FormItem visible={false} />
                </Column>
                <Column dataField="requestDate" caption="MVM/UC Charter Date Time COR Requested" dataType="datetime" format="MM/dd/yyyy HH:mm">
                    <RequiredRule />
                </Column>
                <Column caption="Day of Week COR Requested" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={dayOfWeekRender}>
                    <FormItem visible={false} />
                </Column>
                <Column dataField="originatingCarrier" caption="Carrier">
                    <Lookup dataSource={lkupDataSourceConfiguration} displayExpr="name" valueExpr="code" />
                </Column>
                <Column caption="Charter Type" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={charterTypeRender}>
                    <FormItem visible={false} />
                </Column>
                <Column dataField="seatCapacity" caption="Seat Capacity" dataType="number" editorOptions={{ min: 0, max: 999 }} />
                <Column dataField="totalCost" caption="Cost" format={{
                    type: 'currency',
                    precision: 3, // Set the desired precision here
                }} />

                <Column dataField="tailNumber" caption="Tail Number">
                </Column>

                <Column caption="Flight Hours (Total)" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={flightTotalHoursRender} dataType="number">
                    <FormItem visible={false} />
                </Column>

                <Column dataField="preFlightChecklistRun" caption="Ops Pre-Flight Checklist">
                    <Lookup dataSource={preFlightOptions} displayExpr="name" valueExpr="code" allowClearing={true} />
                </Column>

                <Column dataField="invoiceNumber" caption="Invoice Number" />

                <MasterDetail enabled={true} component={FlightSegment} autoExpandAll={true} />
            </DataGrid>

            <Popup visible={isViewingCharterCarriers} onHiding={hideCharterCarriers} dragEnabled={false}
                closeOnOutsideClick={false} showTitle={true} title="Charter Carriers" width={900} height={500}>
                <DataGrid dataSource={charterCarrierStore} showBorders={true} allowColumnResizing={true} onRowUpdating={onRowUpdating}>
                    <Editing allowAdding={true} mode="popup" allowUpdating={true} />
                    <Column dataField="code" caption="Code" />
                    <Column dataField="name" caption="Name" />
                </DataGrid>
            </Popup>
        </div>);
}

function FlightSegment(props: DataGridTypes.MasterDetailTemplateData) {

    // console.log('incoming props', props);

    const flightsStore = new CustomStore({
        key: 'id',
        load: () => {
            return FetchAPIPromise('CharterBookings/GetAllFlightsForBooking?bookingId=' + props.data.row.data.itineraryId).then((response: any) => {
                //console.log(response);

                response.map((item: CharterSegmentDTO) => {
                    item.arrivalLocalTime = new Date(moment.parseZone(item.arrivalLocalTime).format('yyyy-MM-DDTHH:mm:ss'));
                    item.departureLocalTime = new Date(moment.parseZone(item.departureLocalTime).format('yyyy-MM-DDTHH:mm:ss'));

                    if (item.actualArrivalLocalTime) {
                        item.actualArrivalLocalTime = new Date(moment.parseZone(item.actualArrivalLocalTime).format('yyyy-MM-DDTHH:mm:ss'));
                    }

                    if (item.actualDepartureLocalTime) {
                        item.actualDepartureLocalTime = new Date(moment.parseZone(item.actualDepartureLocalTime).format('yyyy-MM-DDTHH:mm:ss'));
                    }
                });

                return response;
            });
        },
        insert: (values) => {
            return PostAPIPromise('CharterBookings/UpdateFlight', values);
        },
        update: (key, values) => {
            return PostAPIPromise('CharterBookings/UpdateFlight', values);
        }
    });

    const onRowUpdating = (options: DataGridTypes.RowUpdatingEvent) => {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    const departureDelayTimeRender = (data: CharterSegmentDTO) => {
        // TODO: Should use UTC?
        if (data.departureLocalTime && data.actualDepartureLocalTime) {
            var totalDifference = moment(data.actualDepartureLocalTime).diff(moment(data.departureLocalTime), 'minutes');

            return totalDifference;
        }
    }

    const arrivalDelayTimeRender = (data: CharterSegmentDTO) => {
        // TODO: Should use UTC?
        if (data.arrivalLocalTime && data.actualArrivalLocalTime) {
            var totalDifference = moment(data.actualArrivalLocalTime).diff(moment(data.arrivalLocalTime), 'minutes');

            return totalDifference;
        }
    }

    const workOrderRender = (data: CharterSegmentDTO) => {
        if (data.workOrders) {
            return data.workOrders.map((item) => item.workOrderName + '(' + item.workOrderNumber + ')').join(', ');
        }
    }

    const onCellPrepared = (e: DataGridTypes.CellPreparedEvent) => {
        if (e.rowType === "data" && e.column.name === "departureDelayTime") {
            // console.log(e);

            /*
            -Green = Most negative number of minutes – 15 minutes
            -Yellow = 16 minutes-30 minutes
            -Red = Greater than or equal to 31 minutes
            */
            if (e.row.data.departureLocalTime && e.row.data.actualDepartureLocalTime) {
                var totalDifference = moment(e.row.data.actualDepartureLocalTime).diff(moment(e.row.data.departureLocalTime), 'minutes');

                if (totalDifference <= 15) {
                    e.cellElement.style.backgroundColor = 'green';
                }
                else if (totalDifference > 15 && totalDifference <= 30) {
                    e.cellElement.style.backgroundColor = 'yellow';
                }
                else {
                    e.cellElement.style.backgroundColor = 'red';
                }
            }
        }
        else if (e.rowType === "data" && e.column.name === "arrivalDelayTime") {
            // console.log(e);

            /*
            -Green = Most negative number of minutes – 15 minutes
            -Yellow = 16 minutes-30 minutes
            -Red = Greater than or equal to 31 minutes
            */

            if (e.row.data.arrivalLocalTime && e.row.data.actualArrivalLocalTime) {
                var totalDifference = moment(e.row.data.actualArrivalLocalTime).diff(moment(e.row.data.arrivalLocalTime), 'minutes');

                if (totalDifference <= 15) {
                    e.cellElement.style.backgroundColor = 'green';
                }
                else if (totalDifference > 15 && totalDifference <= 30) {
                    e.cellElement.style.backgroundColor = 'yellow';
                }
                else {
                    e.cellElement.style.backgroundColor = 'red';
                }
            }
        }
    }

    const onEditorPreparing = (e: DataGridTypes.EditorPreparingEvent) => {
        //console.log(e);

        if (e.dataField == 'departureIssueComments' && e.row && e.row.data.departureIssueCode == 'None') {
            e.editorOptions.readOnly = true;
        }
        else if (e.dataField == 'arrivalIssueComments' && e.row && e.row.data.arrivalIssueCode == 'None') {
            e.editorOptions.readOnly = true;
        }
    }

    const flightTotalHoursRender = (data: CharterSegmentDTO) => {
        // TODO: Should use UTC?
        if (data.actualDepartureUTCTime && data.actualArrivalUTCTime) {
            var totalDifference = moment(data.actualArrivalUTCTime).diff(moment(data.actualDepartureUTCTime), 'minutes');

            return (Math.round(totalDifference / 15) * 15) / 60;
        }
    }

    const setDepartureIssue = (rowData: CharterSegmentDTO, value: any) => {
        // console.log('Incoming New Value', value);

        rowData.departureIssueCode = value;

        if (value == 'None') {
            rowData.departureIssueComments = 'N/A';
        }
    }

    const setArrivalIssue = (rowData: CharterSegmentDTO, value: any) => {
        // console.log('Incoming New Value', value);

        rowData.arrivalIssueCode = value;

        if (value == 'None') {
            rowData.arrivalIssueComments = 'N/A';
        }
    }

    const setDepartureAirport = (rowData: CharterSegmentDTO, value: any) => {
        rowData.departureAirportCode = value;

        if (value) {
            var airport = airports.find((item) => item.airportCode == value);

            if (airport) {
                rowData.departureAirportName = airport.airportName;
            }
        }
        else {
            rowData.departureAirportName = null;
        }
    }
    const setArrivalAirport = (rowData: CharterSegmentDTO, value: any) => {
        rowData.arrivalAirportCode = value;

        if (value) {
            var airport = airports.find((item) => item.airportCode == value);

            if (airport) {
                rowData.arrivalAirportName = airport.airportName;
            }
        }
        else {
            rowData.arrivalAirportName = null;
        }
    }

    const arrivalCountryRender = (data: CharterSegmentDTO) => {
        //console.log(data);

        if (data.arrivalAirportCountryCode == '5189') {
            return data.arrivalAirportState;
        }
        else {
            return data.arrivalAirportCountryName;
        }
    }

    const onInitNewRow = (e: DataGridTypes.InitNewRowEvent) => {
        e.data.itineraryId = props.data.row.data.itineraryId;
        e.data.operatingCarrier = props.data.row.data.originatingCarrier;
        e.data.statusCode = 'Open';
    }

    return (
        <div>
            <DataGrid dataSource={flightsStore} onRowUpdating={onRowUpdating} onInitNewRow={onInitNewRow} onEditorPreparing={onEditorPreparing}
                showBorders={true} allowColumnResizing={true} onCellPrepared={onCellPrepared}
                keyExpr="id">
                <Editing allowAdding={props.data.row.data.originatingCarrier != undefined} mode="popup" allowUpdating={true} />

                <Column caption="Flight Hours" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={flightTotalHoursRender} dataType="number">
                    <FormItem visible={false} />
                </Column>

                <Column dataField="departureAirportCity" caption="US Departure City" editorOptions={{ readOnly: true }}>
                    <FormItem visible={false} />
                </Column>

                <Column dataField="departureAirportCode" caption="US Departure Airport" setCellValue={setDepartureAirport} editorOptions={{ displayExpr: 'display', valueExpr: 'airportCode' }}>
                    <Lookup dataSource={airports} displayExpr="display" valueExpr="airportCode" allowClearing={true} />
                    <RequiredRule />
                </Column>
                
                <Column dataField="departureLocalTime" caption="US Departure Time Scheduled" dataType="datetime" format="MM/dd/yyyy HH:mm">
                    <RequiredRule />
                </Column>
                <Column dataField="actualDepartureLocalTime" caption="Actual US Departure Time" dataType="datetime" format="MM/dd/yyyy HH:mm" />
                <Column name="departureDelayTime" caption="Delay Time in Minutes (Departures)" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={departureDelayTimeRender} dataType="number">
                    <FormItem visible={false} />
                </Column>

                <Column dataField="departureIssueCode" caption="Departure Issues" setCellValue={setDepartureIssue}>
                    <Lookup dataSource={flightIssues} displayExpr="name" valueExpr="code" allowClearing={true} />
                </Column>

                <Column dataField="departureIssueComments" caption="Comments" />
                <Column dataField="customerNotified" caption="ICE Customer Notified (Name)" />

                <Column dataField="arrivalAirportCity" caption="Arrival City/State" editorOptions={{ readOnly: true }}>
                    <FormItem visible={false} />
                </Column>

                <Column caption="Arrival State or Country" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={arrivalCountryRender}>
                    <FormItem visible={false} />
                </Column>

                <Column dataField="arrivalAirportCode" caption="Arrival Airport" setCellValue={setArrivalAirport} editorOptions={{ displayExpr: 'display', valueExpr: 'airportCode' }}>
                    <Lookup dataSource={airports} displayExpr="display" valueExpr="airportCode" allowClearing={true} />
                    <RequiredRule />
                </Column>

                <Column dataField="arrivalLocalTime" caption="Scheduled Arrival" dataType="datetime" format="MM/dd/yyyy HH:mm">
                    <RequiredRule />
                </Column>
                <Column dataField="actualArrivalLocalTime" caption="Actual Arrival" dataType="datetime" format="MM/dd/yyyy HH:mm" />
                <Column name="arrivalDelayTime" caption="Delay Time in Minutes (Arrivals)" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={arrivalDelayTimeRender} dataType="number">
                    <FormItem visible={false} />
                </Column>

                <Column dataField="arrivalIssueCode" caption="Arrival Issues" setCellValue={setArrivalIssue}>
                    <Lookup dataSource={flightIssues} displayExpr="name" valueExpr="code" allowClearing={true} />
                </Column>

                <Column dataField="arrivalIssueComments" caption="Comments" />

                <Column dataField="statusCode" caption="Status">
                    <RequiredRule />
                    <Lookup dataSource={statuses} displayExpr="name" valueExpr="code" allowClearing={true} />
                </Column>

                <Column dataField="statusComments" caption="Comments" />

                <Column dataField="employeeCount" caption="Number of CFPCS">
                    <FormItem visible={false} />
                </Column>

                <Column dataField="workOrders" caption="Work Order(s)" calculateCellValue={workOrderRender}>
                    <FormItem visible={false} />
                </Column>
            </DataGrid>
        </div>
    );
}

export default OpsCoordinatorCharterFlightInformation;