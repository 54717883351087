import React, { useEffect, useState, useRef } from 'react';

import { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise, withParams, isAccessReadOnly } from '../BaseComponent.js';
import moment from 'moment-timezone';
import { LoadPanel } from 'devextreme-react/load-panel';
import Form, { Item, Label, RequiredRule, StringLengthRule } from 'devextreme-react/form';
import { alert, confirm } from 'devextreme/ui/dialog';
import Button from 'devextreme-react/button';

const timezoneNames = [{
    windowsName: 'Central Standard Time',
    ianaName: 'America/Chicago'
}, {
    windowsName: 'Eastern Standard Time',
    ianaName: 'America/New_York'
}, {
    windowsName: 'Eastern Standard Time',
    ianaName: 'America/Detroit'
}, {
    windowsName: 'US Mountain Standard Time',
    ianaName: 'America/Phoenix'
}, {
    windowsName: 'Mountain Standard Time',
    ianaName: 'America/Denver'
}, {
    windowsName: 'Mountain Standard Time',
    ianaName: 'America/Boise'
}, {
    windowsName: 'Pacific Standard Time',
    ianaName: 'America/Los_Angeles'
    }];

function WorkOrderExternalFlightsContainer(props) {
    const externalFlightForm = useRef();

    const [isBusy, setIsBusy] = useState(false);

    const [externalFlight, setExternalFlight] = useState({});
    const [externalFlightTimeZone, setExternalFlightTimeZone] = useState(' ');

    // console.log('Rendering', props.itineraryItemId);

    useEffect(() => {
        async function fetchData() {
            var externalFlightData = await FetchAPIPromise('ExternalFlights/GetById?id=' + props.itineraryItemId);

            var test = moment.parseZone(externalFlightData.departureLocalTime);
            var timezoneOffset = test.format('Z');

            var foundTimezone = timezoneNames.find((timezoneName) => {
                return timezoneOffset === test.tz(timezoneName.ianaName).format('Z');
            });

            // Convert the datetimeoffset back to a datetime so that the value is not auto converted
            externalFlightData.departureLocalTime = test.format('MM/DD/YYYY HH:mm');

            if (foundTimezone) {
                externalFlightData.departureLocalTimeZone = foundTimezone.ianaName;

                setExternalFlightTimeZone(moment.tz(foundTimezone.ianaName).zoneAbbr());
            }
            else {
                setExternalFlightTimeZone(' ');
            }

            setExternalFlight(externalFlightData);
        }

        fetchData();
    }, [props]);

    const externalFlightForm_FieldDataChanged = async (e) => {
        if (e.dataField == 'departureLocalTimeZone') {
            if (e.value) {
                setExternalFlightTimeZone(moment.tz(e.value).zoneAbbr());
            }
            else {
                setExternalFlightTimeZone(' ');
            }
        }
    }

    const saveExternalFlight = async () => {
        var result = externalFlightForm.current.instance().validate();

        if (result.isValid) {

            try {
                setIsBusy(true);

                //console.log(externalFlightData);

                // Format timezone with departure local time
                var finalOffset = moment(externalFlight.departureLocalTime).tz(externalFlight.departureLocalTimeZone).format('Z')

                var formattedDepartureLocalTime = moment.parseZone(moment.parseZone(externalFlight.departureLocalTime).format('MM/DD/YYYY HH:mm') + ' ' + finalOffset).format('YYYY-MM-DDTHH:mmZ')

                externalFlight.departureLocalTime = formattedDepartureLocalTime;

                const result = await PostAPIPromise('ExternalFlights/SaveExternalFlight', externalFlight);

                // console.log(result);

                if (result.status == 1) {

                    props.updateTripStage('UPDATE', result.resultObject);
                }
                else if (result.status == -1) {
                    alert(result.message);
                }
                else {
                    alert('Failed. Please try again later.');
                }
            }
            finally {
                setIsBusy(false);
            }
        }
    }

    const deleteExternalFlight = async () => {
        
        try {
            var result = await confirm("Are you sure you want to delete this External Flight?");

            if (result) {
                setIsBusy(true);

                // Execute delete
                const result = await DeleteAPIPromise('ExternalFlights/DeleteExternalFlight?flightToDelete=' + props.itineraryItemId);

                // console.log(result);

                if (result.status == 1) {
                    props.updateTripStage('DELETE', externalFlight);
                }
                else if (result.status == -1) {
                    alert(result.message);
                }
            }
        }
        finally {
            setIsBusy(false);
        }
    }

    var componentIsReadOnly = isAccessReadOnly() || props.workOrder.statusID > 1;

    return (
        <>
            <Form ref={externalFlightForm} formData={externalFlight} colCount={5} onFieldDataChanged={externalFlightForm_FieldDataChanged}>

                <Item dataField="responsibleEntity" colSpan={5} editorOptions={{ readOnly: componentIsReadOnly }}>
                    <Label text="Responsible Entity" />
                    <StringLengthRule max={20} />
                    <RequiredRule />
                </Item>

                <Item editorType="dxDateBox" dataField="departureLocalTime" colSpan={3} editorOptions={{ readOnly: componentIsReadOnly, displayFormat: `MM/dd/yyyy HH:mm '${externalFlightTimeZone}'`, type: 'datetime' }}>
                    <Label text="Departure Local Time" />
                    <RequiredRule />
                </Item>
                <Item editorType="dxSelectBox" dataField="departureLocalTimeZone" colSpan={2} editorOptions={{ readOnly: componentIsReadOnly, showClearButton: true, searchEnabled: true, dataSource: timezoneNames, displayExpr: 'ianaName', valueExpr: 'ianaName', dropDownOptions: { width: 200 } }}>
                    <Label text="Time Zone" />
                    <RequiredRule />
                </Item>
            </Form>
            <br />

            <div style={{ textAlign: 'right' }}>
                <Button text="Save" onClick={saveExternalFlight} type="success" stylingMode="contained" disabled={componentIsReadOnly} />
                &nbsp;
                <Button text="Delete" onClick={deleteExternalFlight} type="danger" stylingMode="outlined" disabled={componentIsReadOnly} />
            </div>

            <LoadPanel
                visible={isBusy} />
        </>
    );
}

export default withParams(WorkOrderExternalFlightsContainer);