import React from 'react';
import { msalConfig } from './MsalConfig';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import BaseComponent from '../components/BaseComponent';
import LandingPage from '../LandingPage';
import TimeoutPopup from './TimeoutPopup';

import SessionHelper from './Session'
import CustomMultiAuthPage from '../CustomMultiAuthPage';

export const msalAuth = new PublicClientApplication(msalConfig);

export function withAuth(HocComponent) {
    return class extends BaseComponent {
        constructor(props) {
            super(props);

            this.state = {
                isAuthenticated: false,
                user: {},
                hasError: false,
                errorMessage: null,
                isActive: false,
                systemConfig: {},
                requiresCustomMultiFactor: false,
                NotActiveMessage: "Checking if user is active..."
            };

            this.onSuccess = this.onSuccess.bind(this);
        }

        async componentDidMount() {
            await msalAuth.initialize();

            // Account selection logic is app dependent. Adjust as needed for different use cases.
            // Set active acccount on page load
            const accounts = msalAuth.getAllAccounts();
            if (accounts.length > 0) {
                //console.log('Found accounts');
                msalAuth.setActiveAccount(accounts[0]);
            }
            else {
                //console.log('Found NO accounts');
            }

            msalAuth.addEventCallback((event) => {
                //console.log('Callback event', event);
                //alert('callbac');
                // set active account after redirect
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                    //console.log('Login success');
                    const account = event.payload.account;
                    msalAuth.setActiveAccount(account);
                }
            }, error => {
                //console.log('error', error);

                this.setState({
                    hasError: true,
                    errorMessage: error.errorMessage
                });
            });

            //console.log('get active account', msalAuth.getActiveAccount());

            // handle auth redired/do all initial setup for msal
            msalAuth.handleRedirectPromise().then(async (authResult) => {
                //console.log(authResult);

                //hello();

                // Check if user signed in 
                //console.log('handle redirect promise');
                const account = msalAuth.getActiveAccount();

                //console.log('Account', account);
                if (!account) {
                    //console.log('redirecting');
                    // redirect anonymous user to login page 
                    //msalAuth.loginRedirect({
                    //});                    
                }
                else {
                    //console.log('Checking roles');
                    //console.log(account);

                    var requiresCustomMultiFactor = account.idTokenClaims.roles.length == 1 && account.idTokenClaims.roles.find(element => element === 'TransportSpecialist');

                    //console.log('Setting authenticated');
                    this.setState({
                        isAuthenticated: true,
                        requiresCustomMultiFactor: requiresCustomMultiFactor,
                        user: account
                    });

                    try {
                        //console.log('checking active');
                        var active = await this.FetchAPI("Employee/CheckIfActive");
                        var systemConfig = await this.FetchAPI("UserConfirmLogin/GetSystemConfiguration");
                        //console.log('done checking, active value=', active);
                        this.setState({
                            isActive: active,
                            systemConfig: systemConfig,
                            NotActiveMessage: active ? "Checking if user is active..." : "User is not active"
                        });
                    }
                    catch (ex) {
                        //console.log('inactive error');
                        this.setState({
                            isActive: false,
                            NotActiveMessage: false ? "Checking if user is active..." : "User is not active"
                        });
                    }
                }
            }).catch(err => {
                // TODO: Handle errors
                //console.log(err);

                this.setState({
                    hasError: true,
                    errorMessage: err.errorMessage
                });
            });
        }

        onSuccess() {
            this.setState({
                requiresCustomMultiFactor: false
            });
        }

        async sleep(msec) {
            return new Promise(resolve => setTimeout(resolve, msec));
        }

        render() {
            //console.log('Render');
            //console.log('Authenticated', this.state.isAuthenticated);

            if (!this.state.isAuthenticated) {
                // Render main sheet here
                return (<LandingPage />);
            }
            else if (!this.state.isActive) {
                //console.log('Not active');
                return <div>{this.state.NotActiveMessage}</div>;
            }
            else if (this.state.requiresCustomMultiFactor)
            {
                return (<CustomMultiAuthPage onContinue={this.onSuccess} />);
            }
            else {
                //console.log('Rendering hoccomponent');
                if (!SessionHelper.isTokenExpired) { // check if the expiry time in the storage has not yet passed
                    //console.log('Token is not expired');
                    // Set the expiry time out 30 minutes from now
                    SessionHelper.setExpiry()

                    SessionHelper.expiryTimeoutCallback = async () => {
                        //console.log('Callback');

                        await this.sleep(5000);

                        if (SessionHelper.isTokenExpiredOrNull) {
                            //console.log('Clearing');
                            // clear the session helper
                            SessionHelper.removeExpiry()
                            SessionHelper.stopExpiryTimeout()
                            //console.log('Done');
                            const accounts = msalAuth.getAllAccounts();
                            if (accounts.length > 0) {
                                //console.log('Accounts present');
                                //console.log(accounts[0]);
                                //console.log(accounts[0].idTokenClaims.login_hint);
                                //console.log(accounts[0].idTokenClaims.preferred_username);

                                // log out user
                                msalAuth.logoutRedirect({
                                    logoutHint: accounts[0].idTokenClaims.login_hint,
                                    postLogoutRedirectUri: msalConfig.auth.redirectUri
                                });
                                return;
                            }
                            else {
                                //console.log('Logging out clean');
                                msalAuth.logoutRedirect();
                                return;
                            }
                        } else {
                            //console.log('Trying again?');
                            SessionHelper.resetExpiryTimeout() // try again later
                        }
                    }

                    // Then we'll start the timer
                    SessionHelper.startExpiryTimeout()

                    return (
                        <>
                            <HocComponent auth={this.state} {...this.props} />
                            <TimeoutPopup />
                        </>
                    );
                } else { // clear the expiry value from storage, stop the timeout function and logout the user
                    //console.log('Token is expired');
                    // clear the session helper
                    SessionHelper.removeExpiry()
                    SessionHelper.stopExpiryTimeout()

                    const accounts = msalAuth.getAllAccounts();
                    if (accounts.length > 0) {
                        //console.log('Accounts present');
                        //console.log(accounts[0]);
                        //console.log(accounts[0].idTokenClaims.login_hint);
                        //console.log(accounts[0].idTokenClaims.preferred_username);

                        // log out user
                        msalAuth.logoutRedirect({
                            logoutHint: accounts[0].idTokenClaims.login_hint,
                            postLogoutRedirectUri: msalConfig.auth.redirectUri
                        });
                        return;
                    }
                    else {
                        msalAuth.logoutRedirect();
                        return;
                    }
                }
            }

            return <div>Login in progress...</div>;

            //if (this.state.hasError) {
            //    //console.log('has error');
            //    return <div>{this.state.errorMessage}</div>;
            //}
        }
    };
}