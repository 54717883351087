
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.greenmist.css';
import 'devexpress-gantt/dist/dx-gantt.css';

import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import { Layout } from './components/Layout';
import { withAuth, msalAuth } from './msal/MsalAuthProvider';
import { navbarItems, systemConfig } from './components/SystemConfig';

import './custom.css'
import "bootstrap-icons/font/bootstrap-icons.css"
import { ComplianceDashboardContainer } from './components/Compliance/ComplianceDashboardContainer';
import ComplianceAuditCreateContainer from './components/Compliance/ComplianceAuditCreateContainer';
import { WorkOrderListContainer } from './components/WorkOrder/WorkOrderListContainer';
import { EmployeeListContainer } from './components/Employee/EmployeeListContainer';
import { CommandCenterDashboardContainer } from './components/Dashboards/CommandCenterDashboardContainer';
import { ComplianceChecklistManagementContainer } from './components/Compliance/ComplianceChecklistManagementContainer';
import ComplianceChecklistEditContainer from './components/Compliance/ComplianceChecklistEditContainer';
import ComplianceAuditViewContainer from './components/Compliance/ComplianceAuditViewContainer';
import { InventoryManagementContainer } from './components/Inventory/InventoryManagementContainer';
import { InventoryItemManagementContainer } from './components/Inventory/InventoryItemManagementContainer';
import { DictionaryCityContainer } from './components/Admin/DictionaryCityContainer';
import { DictionaryStateContainer } from './components/Admin/DictionaryStateContainer';
import { ApprovalReasonManagementContainer } from './components/Admin/ApprovalReasonManagementContainer';
import WorkOrderResourcesAltContainer from './components/WorkOrder/WorkOrderResourcesAltContainer';
import WorkOrderResourcesBookTicketContainer from './components/WorkOrder/WorkOrderResourcesBookTicketContainer'
import { PlacementSearchContainer } from './components/Placement/PlacementSearchContainer';
import PlacementEditContainer from './components/Placement/PlacementEditContainer';
import PlacementAssignContainer from './components/Placement/PlacementAssignContainer';
import { HotelFacilityContainer } from './components/Admin/HotelFacilityContainer';
import { FacilityContainer } from './components/Admin/FacilityContainer';
import { MileageContainer } from './components/Admin/MileageContainer';
import WorkOrderScheduleContainer from './components/WorkOrder/WorkOrderScheduleContainer';
import { ScheduleContainer } from './components/Schedule/ScheduleContainer';
import { TimesheetsContainer } from './components/Schedule/TimesheetsContainer';
import { InventoryVehiclesContainer } from './components/Inventory/InventoryVehiclesContainer';
import WorkOrderMilestonesContainer from './components/WorkOrder/WorkOrderMilestonesContainer';
import WorkOrderDetailContainer from './components/WorkOrder/WorkOrderDetailContainer';
import LinkToSupportContainer from './components/WorkOrder/LinkToSupportContainer';
import { ContractListContainer } from './components/Admin/ContractListContainer';
import ContractContainer from './components/Admin/ContractContainer';

import { ICEDashboardContainer } from './components/Dashboards/ICEDashboardContainer';
import { ProjectCodesContainer } from './components/Admin/ProjectCodesContainer';
import { ShowAllLocationContainer } from './components/Dashboards/ShowAllLocationContainer';

import EmployeeEditContainer from './components/Employee/EmployeeEditContainer';
import { EmployeeLeaveRequestsContainer } from './components/Employee/EmployeeLeaveRequestsContainer';
import { OvertimeRequestsContainer } from './components/Employee/OvertimeRequestsContainer';

import TransportSpecialistTripsContainer from './components/CFPCS/TransportSpecialistTripsContainer';
import TransportSpecialistHoursContainer from './components/CFPCS/TransportSpecialistHoursContainer';

import { PickupLocationContainer } from './components/Admin/PickupLocationContainer';

import { CustomerSearchContainer } from './components/ExternalClients/CustomerSearchContainer';
import UploadRequestContainer from './components/ExternalClients/UploadRequestContainer';
import TransportRequestFormContainer from './components/ExternalClients/TransportRequestFormContainer';
import { PlacementChangeRequestContainer } from './components/Placement/PlacementChangeRequestContainer';

import { CustomerHistoryContainer } from './components/Placement/CustomerHistoryContainer';
import PlacementMatchmakingContainer from './components/Placement/PlacementMatchmakingContainer';
import CustomerPlacementContainer from './components/Placement/CustomerPlacementContainer';

import { StagingConfigurationContainer } from './components/Admin/StagingConfigurationContainer';
import { PlacementStagingContainer } from './components/Placement/PlacementStagingContainer';

import { ManagerDashboardContainer } from './components/Dashboards/ManagerDashboardContainer';
import { TransportAnalystDashboardContainer } from './components/Dashboards/TransportAnalystDashboardContainer';
import { TravelBookingDashboardContainer } from './components/Dashboards/TravelBookingDashboardContainer';
import { QADashboardContainer } from './components/Dashboards/QADashboardContainer';

import { FroschHistoryContainer } from './components/Admin/FroschHistoryContainer';
import { EmployeeNonbillHoursContainer } from './components/Schedule/EmployeeNonbillHoursContainer';
import { NonbillTypeContainer } from './components/Schedule/NonbillTypeContainer';

import { UIPHistoryContainer } from './components/Admin/UIPHistoryContainer';

import { HelpContainer } from './components/Help/HelpContainer';
import { SystemMessageListContainer } from './components/Admin/SystemMessageListContainer';

import WorkOrderTripStatusContainer from './components/WorkOrder/WorkOrderTripStatusContainer';

import WorkOrderGroundTransportContainer from './components/WorkOrder/WorkOrderGroundTransportContainer';
import HotelStayReasonComponent from './components/Admin/HotelStayReasonComponent';

import WorkOrderHoursOfServiceWizardContainer from './components/WorkOrder/WorkOrderHoursOfServiceWizardContainer';

import OpsCoordinatorCharterFlightInformation from './components/WorkOrder/OpsCoordinatorCharterFlightInformation';

import NotificationManagement from './components/Admin/NotificationManagement';
import WorkOrderDetailsNew from './components/WorkOrder/WorkOrderDetailsNew';

import config from 'devextreme/core/config'; 
import { licenseKey } from './devextreme-license'; 
 
config({ licenseKey }); 

class RootApp extends Component {
    render() {
        //console.log('Rendering');

        console.log('App properties', this.props);

        var userRoles = msalAuth.getActiveAccount().idTokenClaims.roles;

        var adminMenuItems = [];

        const userRoutes = [];

        //TODO: Logic here could be cleaned up a bit. Some things are redundant and/or can be consolidated. -RPM
        if (userRoles.find(element => element === 'TransportSpecialist')) {
            userRoutes.push(<Route key="cfpcs-trips" path='cfpcs-trips' element={<TransportSpecialistTripsContainer />} />);
            userRoutes.push(<Route key="cfpcs-hours" path='cfpcs-hours' element={<TransportSpecialistHoursContainer />} />);
            userRoutes.push(<Route key="help" path='/help' element={<HelpContainer />} />);
            userRoutes.push(<Route key="system-message-history" path='/system-message-history' element={<SystemMessageListContainer />} />);

            navbarItems.push(
                { text: "My Trips", path: '/cfpcs-trips' },
                { text: "My Hours", path: '/cfpcs-hours' }
            );
        }

        if (userRoles.find(element => element === 'ExternalClient') || userRoles.find(element => element === 'BulkImport')) {
            // Be very conservative about any routes enabled for external clients
            userRoutes.push(<Route key="customer-portal" path='/customer-portal' element={<CustomerSearchContainer />} />);

            if (userRoles.find(element => element === 'BulkImport')) {
                userRoutes.push(<Route key="upload-request-root" path='/upload-request' element={<UploadRequestContainer />}>
                    <Route key="upload-request-path" path=":returnPath" element={<UploadRequestContainer />} />
                    <Route key="upload-request" path="" element={<UploadRequestContainer />} />
                </Route>);
            }

            if (userRoles.find(element => element === 'ExternalClient')) {
                // Push manual entry route(s)
                userRoutes.push(<Route key="initial-transport-request" path='/initial-transport-request' element={<TransportRequestFormContainer />} />);
            }

            navbarItems.push({ text: "Client Portal", path: '/customer-portal' })
        }
        else {
            if (userRoles.find(element => element === 'ProgramManager' || element === 'ProgramManager_ReadOnly')) {
                userRoutes.push(<Route key="manager-dashboard" path='/manager-dashboard' element={<ManagerDashboardContainer />} />);
                userRoutes.push(<Route key="ice-dashboard" path='/ice-dashboard' element={<ICEDashboardContainer />} />);

                userRoutes.push(<Route key="groundtransport-dashboard" path='/groundtransport-dashboard' element={<WorkOrderGroundTransportContainer />} />);
                userRoutes.push(<Route key="groundtransport-hoswizard" path='/groundtransport-hoswizard/:id' element={<WorkOrderHoursOfServiceWizardContainer />} />);

                userRoutes.push(<Route key="charterbookings-dashboard" path='/charterbookings-dashboard' element={<OpsCoordinatorCharterFlightInformation />} />);

                navbarItems.push({
                    text: "Manager Dashboards",
                    items: [
                        { text: "Roster Management", path: '/manager-dashboard' },
                        { text: "ICE Dashboard", path: '/ice-dashboard' },
                        { text: "Ground Transport", path: '/groundtransport-dashboard' },
                        { text: 'Charter Bookings', path: '/charterbookings-dashboard' }
                    ],
                    style: { color: "white" }
                });
            }

            if (userRoles.find(element => element === 'TransportAnalyst') || userRoles.find(element => element === 'TransportAnalyst_ReadOnly')) {
                userRoutes.push(<Route key="ta-dashboard" path='/ta-dashboard' element={<TransportAnalystDashboardContainer />} />);
                userRoutes.push(<Route key="travel-booking-dashboard" path='/travel-booking-dashboard' element={<TravelBookingDashboardContainer />} />);
                userRoutes.push(<Route key="commandcenterdashboard" path='/commandcenterdashboard' element={<CommandCenterDashboardContainer />} />);
                userRoutes.push(<Route key="show-all-location" path='/show-all-location' element={<ShowAllLocationContainer />} />);

                navbarItems.push({
                    text: "TA Dashboards",
                    items: [
                        { text: "Daily Heads Up", path: '/ta-dashboard' },
                        { text: "Travel Bookings", path: '/travel-booking-dashboard' },
                        { text: "Cmd Cntr Dashboard", path: '/commandcenterdashboard' },
                        { text: "Show All Location", path: '/show-all-location' }
                    ]
                });
            }

            if (userRoles.find(element => element === 'ComplianceManager') || userRoles.find(element => element == 'ComplianceManager_ReadOnly')) {
                userRoutes.push(<Route key="compliance" path='/compliance' element={<ComplianceDashboardContainer />} />);
                userRoutes.push(<Route key="view-audit" path='/view-audit/:id' element={<ComplianceAuditViewContainer />} />);

                navbarItems.push({ text: "Compliance", path: '/compliance' });
            }

            if (userRoles.find(element => element === 'ComplianceManager')) {
                userRoutes.push(<Route key="create-audit" path='/create-audit' element={<ComplianceAuditCreateContainer />} />);
                userRoutes.push(<Route key="manage-checklists" path='/manage-checklists' element={<ComplianceChecklistManagementContainer />} />);
                userRoutes.push(<Route key="edit-checklist" path='/edit-checklist/:id' element={<ComplianceChecklistEditContainer />} />);
                userRoutes.push(<Route key="create-checklist" path='/create-checklist' element={<ComplianceChecklistEditContainer />} />);
            }

            if (userRoles.find(element => element === 'ProgramManager' || element === 'ProgramManager_ReadOnly') || userRoles.find(element => element === 'TransportAnalyst') || userRoles.find(element => element === 'TransportAnalyst_ReadOnly')) {
                userRoutes.push(<Route key="workorders" path='/workorders' element={<WorkOrderListContainer />} />);
                userRoutes.push(<Route key="workorder-detail" path="/workorder-detail/:id" element={<WorkOrderDetailContainer />} />);
                userRoutes.push(<Route key="workorder-detail-new" path="/workorder-detail-new/:id" element={<WorkOrderDetailsNew />} />);
                userRoutes.push(<Route key='trip-status' path='/trip-status/:id' element={<WorkOrderTripStatusContainer />} />);
                userRoutes.push(<Route key="workorder-resources" path='/workorder-resources/:id' element={<WorkOrderResourcesAltContainer />} />);
                userRoutes.push(<Route key="workorder-milestones" path="/workorder-milestones/:id" element={<WorkOrderMilestonesContainer />} />);
                userRoutes.push(<Route key="workorder-schedule" path='/workorder-schedule/:id' element={<WorkOrderScheduleContainer />} />);
                userRoutes.push(<Route key="book-ticket" path='/book-ticket/:id' element={<WorkOrderResourcesBookTicketContainer />} />);
                userRoutes.push(<Route key="link-to-support" path="/link-to-support/:id" element={<LinkToSupportContainer />} />);

                userRoutes.push(<Route key="search-placements" path='/search-placements' element={<PlacementSearchContainer />} />);
                userRoutes.push(<Route key="edit-placement-workorder" path='/edit-placement/:id/:workOrderId' element={<PlacementEditContainer />} />);
                userRoutes.push(<Route key="edit-placement" path='/edit-placement/:id' element={<PlacementEditContainer />} />);
                userRoutes.push(<Route key="upload-request" path='/upload-request' element={<UploadRequestContainer />} />);
                userRoutes.push(<Route key="placement-change-requests" path='/placement-change-requests' element={<PlacementChangeRequestContainer />} />);
                userRoutes.push(<Route key="matchmake-placements" path='/matchmake-placements' element={<PlacementMatchmakingContainer />} />);
                userRoutes.push(<Route key="stage-placements" path='/stage-placements' element={<PlacementStagingContainer />} />);

                userRoutes.push(<Route key="customer-history" path='/customer-history' element={<CustomerHistoryContainer />} />);
                userRoutes.push(<Route key="customer-placement" path='/customer-placement/:placementId/:customerId' element={<CustomerPlacementContainer />} />);

                navbarItems.push({ text: "Work Orders", path: '/workorders' });

                navbarItems.push({
                    text: "Placements",
                    items: [
                        { text: "Placements", path: '/search-placements' },
                        { text: "Customer History", path: '/customer-history' }
                    ]
                });
            }

            if (userRoles.find(element => element === 'ProgramManager') || userRoles.find(element => element === 'TransportAnalyst')) {
                userRoutes.push(<Route key="create-workorder-support" path="/create-workorder/:type/:supportWorkOrderId" element={<WorkOrderDetailContainer />} />);
                userRoutes.push(<Route key="create-workorder" path="/create-workorder/:type" element={<WorkOrderDetailContainer />} />);
                userRoutes.push(<Route key="assign-placements" path='/assign-placements/:id' element={<PlacementAssignContainer />} />);

                userRoutes.push(<Route key="add-placement-workorder" path='/add-placement/:workOrderId' element={<PlacementEditContainer />} />);
                userRoutes.push(<Route key="add-placement" path='/add-placement' element={<PlacementEditContainer />} />);
                userRoutes.push(<Route key="copy-placement-workorder" path='/copy-placement/:placementToCopyId/:workOrderId' element={<PlacementEditContainer />} />);
                userRoutes.push(<Route key="copy-placement" path='/copy-placement/:placementToCopyId' element={<PlacementEditContainer />} />);
            }

            // Splitting out some exlusive assignments in case someone ONLY has TimekeepingAssistant
            if (!userRoles.find(element => element === 'ProgramManager' || element === 'ProgramManager_ReadOnly') && userRoles.find(element => element === 'TimekeepingAssistant')) {
                userRoutes.push(<Route key="schedule" path='/schedule' element={<ScheduleContainer />} />);
                userRoutes.push(<Route key="nonbill-hours" path='/nonbill-hours' element={<EmployeeNonbillHoursContainer />} />);

                navbarItems.push({
                    text: "Timekeeping",
                    items: [
                        { text: "Scheduled Hours", path: '/schedule' },
                        { text: "Nonbill Hours", path: '/nonbill-hours' }
                    ]
                });
            }
            else if (userRoles.find(element => element === 'ProgramManager' || element === 'ProgramManager_ReadOnly')) {
                userRoutes.push(<Route key="schedule" path='/schedule' element={<ScheduleContainer />} />);
                userRoutes.push(<Route key="nonbill-hours" path='/nonbill-hours' element={<EmployeeNonbillHoursContainer />} />);
                userRoutes.push(<Route key="timesheets" path='/timesheets' element={<TimesheetsContainer />} />);

                userRoutes.push(<Route key="admin-destination-city" path='/admin-destination-city' element={<DictionaryCityContainer />} />);
                userRoutes.push(<Route key="admin-destination-state" path='/admin-destination-state' element={<DictionaryStateContainer />} />);

                if (this.props.auth && this.props.auth.systemConfig.requireStrictTripComplianceReasons) {
                    userRoutes.push(<Route key="admin-approval-reasons" path='/admin-approval-reasons' element={<ApprovalReasonManagementContainer />} />);
                }

                userRoutes.push(<Route key="admin-hotel-facility" path='/admin-hotel-facility' element={<HotelFacilityContainer />} />);
                userRoutes.push(<Route key="admin-facility" path='/admin-facility' element={<FacilityContainer />} />);
                userRoutes.push(<Route key="admin-pickup-location" path='/admin-pickup-location' element={<PickupLocationContainer />} />);
                userRoutes.push(<Route key="admin-mileage" path='/admin-mileage' element={<MileageContainer />} />);

                userRoutes.push(<Route key="notification-management" path='/notification-management' element={<NotificationManagement />} />);

                userRoutes.push(<Route key="admin-hotel-stay-reason" path='/admin-hotel-stay-reason' element={<HotelStayReasonComponent />} />);

                userRoutes.push(<Route key="staging-configuration" path='/staging-configuration' element={<StagingConfigurationContainer />} />);
                userRoutes.push(<Route key="project-codes" path="/project-codes" element={<ProjectCodesContainer />} />);
                userRoutes.push(<Route key="nonbill-type" path="/nonbill-type" element={<NonbillTypeContainer />} />);
                userRoutes.push(<Route key="contracts" path='/contracts' element={<ContractListContainer />} />);
                userRoutes.push(<Route key="contract-details-id" path='/contract-details/:id' element={<ContractContainer />} />);
                userRoutes.push(<Route key="contract-details" path='/contract-details' element={<ContractContainer />} />);

                userRoutes.push(<Route key="employees" path='/employees' element={<EmployeeListContainer />} />);
                userRoutes.push(<Route key="leave-requests" path='/leave-requests' element={<EmployeeLeaveRequestsContainer />} />);
                userRoutes.push(<Route key="overtime-requests" path='/overtime-requests' element={<OvertimeRequestsContainer />} />);

                userRoutes.push(<Route key="edit-employee-root" path='/edit-employee' element={<EmployeeEditContainer />}>
                    <Route key="edit-employee-id" path=":id" element={<EmployeeEditContainer />} />
                    <Route key="edit-employee" path="" element={<EmployeeEditContainer />} />
                </Route>);

                navbarItems.push({
                    text: "Employees",
                    items: [
                        { text: "Employee List", path: '/employees' },
                        { text: "Leave Requests", path: '/leave-requests' },
                        { text: "Overtime Requests", path: '/overtime-requests' }
                    ]
                });

                navbarItems.push({
                    text: "Timekeeping",
                    items: [
                        { text: "Scheduled Hours", path: '/schedule' },
                        { text: "Nonbill Hours", path: '/nonbill-hours' },
                        { text: "Timesheets", path: '/timesheets' }
                    ]
                });

                adminMenuItems.push(
                    { text: "City Management", path: '/admin-destination-city' },
                    { text: "State Management", path: '/admin-destination-state' },
                    { text: "Pickup Location Management", path: '/admin-pickup-location' },
                    { text: "Facility Management", path: '/admin-facility' });

                if (this.props.auth && this.props.auth.systemConfig.requireStrictTripComplianceReasons) {
                    adminMenuItems.push({ text: "UC or SC Approval Reasons", path: '/admin-approval-reasons' });
                }

                adminMenuItems.push(
                    { text: "Hotel Management", path: '/admin-hotel-facility' },
                    { text: "Hotel Stay Reasons", path: '/admin-hotel-stay-reason' },
                    { text: "Staging Configuration", path: '/staging-configuration' },
                    { text: "Contract/Office Management", path: '/contracts' },
                    { text: "Maintain Project Codes", path: '/project-codes' },
                    { text: "Mileage Management", path: '/admin-mileage' },
                    { text: "Notification Management", path: '/notification-management' }
                );
            }

            if (userRoles.find(element => element !== 'TransportSpecialist')) {
                navbarItems.push({ text: "Reports", path: `/${systemConfig.reportServerName}/Report/ReportFilter.aspx` })
            }

            if (userRoles.find(element => element === 'SystemAdmin')) {
                userRoutes.push(<Route key="frosch-history" path='/frosch-history' element={<FroschHistoryContainer />} />);
                userRoutes.push(<Route key="qa-dashboard" path="/qa-dashboard" element={<QADashboardContainer />} />);
                userRoutes.push(<Route key="system-message-management" path='/system-message-management' element={<SystemMessageListContainer />} />);
                userRoutes.push(<Route key="uip-history" path='/uip-history' element={<UIPHistoryContainer />} />);

                adminMenuItems.push(
                    { text: "Frosh History", path: '/frosch-history' },
                    { text: "QA Dashboard", path: '/qa-dashboard' },
                    { text: "System Message Management", path: '/system-message-management' },
                    { text: 'UIP History', path: 'uip-history' }
                );
            }

            if (userRoles.find(element => element === 'TransportCoordinator') || userRoles.find(element => element === 'TransportCoordinator_ReadOnly')) {
                userRoutes.push(<Route key="inventory-management" path='/inventory-management' element={<InventoryManagementContainer />} />);
                userRoutes.push(<Route key="inventory-item-management" path='/inventory-item-management' element={<InventoryItemManagementContainer />} />);
                userRoutes.push(<Route key="inventory-vehicle-management" path="/inventory-vehicle-management" element={<InventoryVehiclesContainer />} />);

                adminMenuItems.push(
                    { text: "Inventory Management", path: '/inventory-management' },
                    { text: "Inventory Items", path: '/inventory-item-management' },
                    { text: "Vehicle Management", path: '/inventory-vehicle-management' }
                );
            }

            userRoutes.push(<Route key="help" path='/help' element={<HelpContainer />} />);
            userRoutes.push(<Route key="system-message-history" path='/system-message-history' element={<SystemMessageListContainer />} />);

            if (adminMenuItems.length > 0) {
                navbarItems.push({ text: "Admin", items: adminMenuItems });
            }
        }

        var defaultPath = '/';

        if (userRoutes.length > 0) {
            defaultPath = userRoutes[0].props.element;
        }

        userRoutes.push(<Route key="default"
            exact
            path="/"
            element={defaultPath}
        />);

        //console.log(userRoutes[0]);

        //console.log('Default Route path', defaultPath);

        return (
            <Layout>
                <Routes>
                    {userRoutes}
                </Routes>
            </Layout>
        );
    }
}

export const App = withAuth(RootApp);