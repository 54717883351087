import React, { Component } from 'react';
import './NavMenu.css';
import { msalAuth } from '../msal/MsalAuthProvider';
import { systemConfig, navbarItems } from './SystemConfig';
import SessionHelper from '../msal/Session';
import { Popup } from 'devextreme-react/popup';
import Form, { SimpleItem } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import BaseComponent, { withParams, PostAPIPromise, FetchAPIPromise } from './BaseComponent.js';
import { alert } from 'devextreme/ui/dialog';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';

const dateEditorOptions = { type: "datetime", stylingMode: "filled" };

class NavMenu extends BaseComponent {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.issueReport = React.createRef();

        this.state = {
            collapsed: true,

            showBugReport: false,
            categoryOptions: ["Work Order", "Placement", "Reporting", "Admin configurations (Add/Remove)", "Other"],
            bugReport: {
                firstName: null,
                lastName: null,
                email: null,
                browser: null,
                reportInfo: null,
                dataDump: null,
                subject: null,
                category: null,
                version: null,
                appVersion: null,
                location: null,
                isMobile: false
            },

            showSystemMessageDetails: false,
            systemMessage: null
        };
    }

    componentDidMount() {
        super.componentDidMount();

        this.checkForSystemMessage();
    }

    logOut = (e) => {
        e.preventDefault();

        //console.log('logging out');
        SessionHelper.removeExpiry()
        SessionHelper.stopExpiryTimeout()

        const accounts = msalAuth.getAllAccounts();
        if (accounts.length > 0) {
            //console.log('Accounts present');
            //console.log(accounts[0]);
            //console.log(accounts[0].idTokenClaims.login_hint);
            //console.log(accounts[0].idTokenClaims.preferred_username);
            // log out user
            //msalAuth.logoutRedirect({
            //    account: accounts[0],
            //    logoutHint: accounts[0].idTokenClaims.login_hint,
            //    postLogoutRedirectUri: msalConfig.auth.redirectUri
            //})
            // TODO: Use v2 logout redirect
            msalAuth.logoutRedirect(
                { extraQueryParameters: { logout_hint: accounts[0].idTokenClaims.login_hint } }
            );
            //window.location.href = msalConfig.auth.authority + "/oauth2/logout?post_logout_redirect_uri=" + msalConfig.auth.redirectUri + "&logout_hint=" + accounts[0].idTokenClaims.login_hint;
            //window.location.href = "https://login.microsoftonline.com/common/oauth2/logout";
            return;
        }
        else {
            // TODO: Use v2 logout redirect
            msalAuth.logoutRedirect();
            //window.location.href = msalConfig.auth.authority + "/oauth2/logout?post_logout_redirect_uri=" + msalConfig.auth.redirectUri + "&logout_hint=" + accounts[0].idTokenClaims.login_hint;
            //window.location.href = "https://login.microsoftonline.com/common/oauth2/logout";
            return;
        }
    }

    openIssueReport = () => {
        var name = msalAuth.getActiveAccount().name.split(' ');

        this.setState({
            showBugReport: true,
            bugReport: {
                firstName: name[0],
                lastName: name[name.length - 1],
                email: msalAuth.getActiveAccount().username,
                browser: null, //TODO: implement a way to do this
                reportInfo: null,
                dataDump: null, //TODO: Maybe implement something where we grab the errors from the console somehow?
                subject: systemConfig.systemName + " Web Issue Reported",
                category: null,
                version: navigator.platform,
                appVersion: systemConfig.systemName + " v" + systemConfig.systemVersion,
                location: window.location.href
            }
        });
    }

    hideIssueReport = () => {
        this.setState({
            showBugReport: false
        });
    }

    submitBugReport = async (e) => {
        var validationResult = e.validationGroup.validate();

        if (validationResult.isValid) {
            try {
                this.issueReport.current.instance().hide();
                var result = await PostAPIPromise("UserConfirmLogin/SubmitBugReport", this.state.bugReport);

                if (result.status == 1) {
                    alert("Issue reported!");
                }
                else {
                    alert("Failed to send issue report.");
                }
            }
            catch (ex) {
                alert("Failed to send issue report.");
            }
        }
    }

    cancelBugReport = () => {
        this.issueReport.current.instance().hide();
    }

    openSystemMessageDetails = (e) => {
        e.preventDefault();

        this.setState({
            showSystemMessageDetails: true
        });
    }

    hideSystemMessageDetails = () => {
        this.setState({
            showSystemMessageDetails: false
        });
    }

    checkForSystemMessage = async () => {
        const result = await this.FetchAPI('SystemMessage/CheckForSystemMessage')

        //console.log("System Message Fetched: ", result);

        if (result.status === 1) {
            this.setState({
                systemMessage: result.systemMessage
            });
        }
    }

    renderNavMenu = () => {
        return navbarItems.map((item) => {
            if (item.path) {
                return (<Nav.Link href={item.path}>{item.text}</Nav.Link>);
            }
            else {
                return (<NavDropdown title={item.text}>
                    {item.items.map((dropdownItem) => {
                        return <NavDropdown.Item href={dropdownItem.path}>{dropdownItem.text}</NavDropdown.Item>
                    })}
                </NavDropdown>);
            }
        });
    }

    render() {
        return (
            <header className='mb-4'>
                <Navbar id="navbar" collapseOnSelect expand="lg" bg="customBlue" variant="dark">
                    <Container>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Brand id='brand' href="/">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0px" y="0px" viewBox="0 0 144 119.4" xmlSpace="preserve" fill="currentColor">
	                            <g>
		                            <path d="M116.2,56.6c-1,2.7-3.4,8.5-3.5,8.7c-0.1-0.2-2.7-5.9-3.7-8.6c-0.2-0.4-0.4-0.7-0.7-0.8H98c-0.3,0.1-0.5,0.4-0.6,0.8
			                            c-3.1,8.8-20.3,54.6-21.3,57.3h10.8c2.6-6.7,14.1-36.1,16.6-42.7c0.1-0.3,0.3-0.6,0.5-1c0.3,0.3,0.4,0.7,0.5,1
			                            c2.5,6.4,8.3,19.9,8.3,19.9c0.1-0.3,5.7-13.9,8.4-20.4c0.1-0.3,0.1-0.7,0.4-0.7c0.3,0,0.3,0.4,0.4,0.7c0.1,0.1,0.1,0.2,0.1,0.3
			                            c2.6,6.6,8,20,8,20.1h9.9c-0.3-0.3-8.7-23.3-12.8-34.5c-0.2-0.5-0.4-0.7-0.7-0.8h-9.8C116.6,56,116.4,56.2,116.2,56.6z"></path>
		                            <path d="M84,56.8c-3.5,9.3-11.1,28.9-11.1,28.9s-7.7-19.6-11.2-28.9c-0.2-0.5-0.4-0.8-0.8-0.9h-10c-0.2,0.2-0.1,0.5,0.1,1
			                            c2.7,7,21.6,57.1,21.6,57.1s17.5-45.7,21.9-57.1c0.2-0.5,0.3-0.9,0.1-1h-9.9C84.4,56,84.2,56.3,84,56.8z"></path>
		                            <path d="M36.7,56.7c-1,2.6-2.1,5.2-3.2,7.7c-0.1,0.3-0.1,0.6-0.5,0.8c-0.1-0.3-0.3-0.5-0.4-0.8c-1-2.7-2-5.3-2.9-8
			                            c-0.1-0.3-0.2-0.5-0.4-0.6H19c-0.3,0.1-0.4,0.4-0.6,0.8C17.7,58.9,5.5,91.2,5.5,91.2h9.8l8-20.4c0,0,0.2-0.8,0.4-0.8
			                            c0.4,0,0.4,0.5,0.5,0.9c2.8,6.5,8.9,20.4,8.9,20.4s5.5-13.8,8-20.3c0.1-0.3,0.1-0.7,0.5-0.9c0.1,0.3,0.2,0.6,0.4,0.9
			                            c2.6,6.8,13.7,36.3,16.3,43.1h10.8C65,102.4,52.3,68.1,48.2,56.6c-0.1-0.4-0.3-0.6-0.5-0.7H37.3C37,56,36.8,56.2,36.7,56.7z"></path>
		                            <path d="M59.7,52c2.5-6.4,5-12.7,7.6-19.1c0.3-0.7,0.2-1.4-0.2-2c-2.2-3.5-4.3-7-6.5-10.5c-0.7-1.2-1.4-2.4-2.5-3.4
			                            c-0.6-0.5-1.2-0.8-1.9-0.1c-0.2,0.2-0.3,0.4-0.6,0.3c-0.3-0.2-0.2-0.5-0.2-0.8c0.3-2.1,2.2-3.2,4.2-2.3c0.4,0.2,0.8,0.4,1.2,0.6
			                            c0.8,0.5,1.5,0.5,2.3-0.1c0.9-0.7,1.9-1,2.9-0.6c2.9,1.3,5.7,2.5,7,6c3.8,10.6,7.9,21.1,11.9,31.6c0.1,0.4,0.3,0.7,0.6,0.8h40.1
			                            c0,0-1.6-4.7-2.2-6.6c-0.3-0.8-0.6-1.1-1.5-1.1c-9.9,0-19.8,0-29.6,0c-1,0-1.4-0.3-1.8-1.2c-3.7-10.1-7.4-20.1-11.1-30.2
			                            c-0.5-1.3-1.3-2.2-2.6-2.6c-1.4-0.5-2.9-1-4.3-1.5c-3.3-1.2-6.8-2.2-10.1-3.6c-0.8-0.2-1.6-0.2-2.4,0c-1.4,0.7-2.1,2-2.6,3.4
			                            c-0.3,0.8-0.7,1-1.4,0.5c-0.7-0.5-1.5-1-2.3-1.3c-3.5-1.3-4.6-0.4-5.4,2.4c-0.8,2.7-1.7,5.5-2.5,8.2c-0.2,0.7-0.9,1.6-0.3,2
			                            c0.6,0.4,1.2-0.6,1.7-1c1.9-1.3,3.7-1.2,5.2,0.5c0.4,0.5,0.8,1.1,1.2,1.6c1.7,2.7,3.4,5.4,5.1,8.1c0.7,1,0.7,1.9,0.2,2.9
			                            c-1.5,3.4-2.9,6.8-4.2,10.3c-0.4,0.9-0.9,1.2-1.8,1.2c-9.6,0-19.3,0.1-28.9,0.1c-0.7,0-1.1,0.2-1.3,0.9c-0.7,2-2.3,6.8-2.3,6.8h39
			                            C59.5,52.4,59.6,52.2,59.7,52z"></path>
	                            </g>
                            </svg>{systemConfig.systemName}
                        </Navbar.Brand>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav id='navMenu' className="me-auto">
                                {this.renderNavMenu()}
                            </Nav>
                        </Navbar.Collapse>
                        <Nav id='helpMenu'>
                            <Dropdown as={NavItem} align='end'>
                                <Dropdown.Toggle as={NavLink}>
                                    <i className="dx-icon-help" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="/help">Help</Dropdown.Item>
                                    <Dropdown.Item onClick={this.openIssueReport}>Report Issue</Dropdown.Item>
                                    <Dropdown.Item href="/system-message-history">System Message History</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Header>{msalAuth.getActiveAccount().username}</Dropdown.Header>
                                    <Dropdown.Item onClick={this.logOut}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Container>
                </Navbar>

                {this.state.systemMessage
                    ? <div>
                        <div className="system-message align-self-end">
                            System Message: {this.state.systemMessage.bannerMessage} (<a style={{ color: "lightskyblue" }} onClick={this.openSystemMessageDetails} href="#">Info</a>)
                        </div>
                        <Popup visible={this.state.showSystemMessageDetails} title="System Message"
                            height="auto" width="auto"
                            hideOnOutsideClick={false}
                            onHiding={this.hideSystemMessageDetails}>
                            <Form colCount={2} alignItemLabels={false} labelLocation="top" formData={this.state.systemMessage} readOnly={true}>
                                {/* TODO: If we update DevExtreme to v23, this would be a fantastic place to use the new DateRangeBox UI component. -RPM */ console.log("system message", this.state.systemMessage.isServiceDisruption)}
                                <SimpleItem dataField="serviceDisruptionStartDate" editorType="dxDateBox" editorOptions={dateEditorOptions} label={{ text: "System will be down from" }} visible={this.state.systemMessage.isServiceDisruption} />
                                <SimpleItem dataField="serviceDisruptionEndDate" editorType="dxDateBox" editorOptions={dateEditorOptions} label={{ text: "To" }} visible={this.state.systemMessage.isServiceDisruption} />
                                <SimpleItem dataField="messageDetails" editorType="dxHtmlEditor" colSpan={2} />
                            </Form>
                            <br /><br />
                        </Popup>
                    </div>
                    : <></>
                }
                <Popup visible={this.state.showBugReport} title="Report Issue"
                    height="auto" width="50vw"
                    hideOnOutsideClick={false}
                    onHiding={this.hideIssueReport}
                    ref={this.issueReport}>
                    <Form colCount={1} id="form" labelMode="floating" formData={this.state.bugReport} validationGroup="issueForm" className="mb-3">
                        <SimpleItem dataField="category" isRequired={true} editorType="dxSelectBox" editorOptions={{ items: this.state.categoryOptions }} />
                        <SimpleItem dataField="reportInfo" isRequired={true} editorType="dxTextArea" editorOptions={{ height: "40vh" }} label={{ text: "Issue Description" }} />
                    </Form>

                    <Button onClick={this.submitBugReport} text="Submit" validationGroup="issueForm" type="success" className="float-end" />
                    <Button onClick={this.cancelBugReport} text="Cancel" className="float-end me-2" />
                </Popup>
                <br />
            </header>
        );
    }
}

export default withParams(NavMenu);