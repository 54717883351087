
import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Editing, RequiredRule, StringLengthRule, Column, Export } from 'devextreme-react/data-grid';
import BaseComponent, { FetchAPIPromise, PostAPIPromise } from '../BaseComponent';
import * as $ from 'jquery';

const customDataSource = new CustomStore({
    key: 'code',
    load: () => {
        return FetchAPIPromise('DictionaryOutOfComplianceApprovalReason');
    },
    insert: (values) => {
        return PostAPIPromise('DictionaryOutOfComplianceApprovalReason', values);
    }
});

export class ApprovalReasonManagementContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            countries: []
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return "UC or SC Approval Reasons";
    }

    onInitNewRow(e) {
        // Set active value default to true
        e.data.isActived = true;
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    render() {
        return (
            <div className="container">
                <h1>UC or SC Approval Reasons</h1>

                <div className="container">
                    <DataGrid dataSource={customDataSource}
                        showBorders={true} allowColumnResizing={true}
                        keyExpr="code" onInitNewRow={this.onInitNewRow} onRowUpdating={this.onRowUpdating} onExporting={this.onExporting}>
                        <Export enabled={true} fileName={"approvalReasonManagment"} />
                        <Editing mode="popup" allowAdding={!this.IsReadOnly()} />
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} />
                        <Column
                            caption="Reason"
                            dataField="reason">
                            <RequiredRule />
                        />
                        </Column>
                    </DataGrid>
                    <br />
                </div>
            </div>
        );
    }
}