import React from 'react';
import PropTypes from 'prop-types';
import CustomStore from 'devextreme/data/custom_store';
import BaseComponent, { FetchAPIPromise, PostAPIPromise } from '../BaseComponent.js';
import DataGrid, { FilterRow, Column, GroupPanel, Selection, MasterDetail, Button as GridButton, Editing, Export } from 'devextreme-react/data-grid';
import { msalAuth } from '../../msal/MsalAuthProvider';
import Popup from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react/scroll-view';
import Button from 'devextreme-react/button';
import Form, { Item, RequiredRule } from 'devextreme-react/form';

export class WorkOrderCustomerFlightsContainer extends BaseComponent {
    static propTypes = {
        workOrder: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.workOrderName = props.workOrder.workOrderName;
        this.state = {
            userIsSysAdmin: false,

            isMovingFlight: false,
            itineraryItemId: null,
            eligibleFlightEmployees: [],

            isCorrectingFlight: false,
            eligibleFlightCustomers: [],

            isMergingCustomerFlight: false,
            overwritingMergeItem: [],
            eligibleMergeFlights: [],

            isMovingWorkOrder: false,
            destinationWorkOrder: {
                workOrderNumber: ''
            }
        };
    }

    GetData = async () => {
        var userRoles = msalAuth.getActiveAccount().idTokenClaims.roles;

        var isUserSystemAdmin = userRoles.findIndex(element => element === 'SystemAdmin') > -1;
        //console.log('User ' + (isUserSystemAdmin ? 'IS' : 'IS NOT') + ' a Sys Admin');

        await this.setState({
            userIsSysAdmin: isUserSystemAdmin
        });
    }

    flightsStore = new CustomStore({
        key: 'flightItineraryId',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(this.props.workOrder.id);

            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderCustomerFlights/' + this.props.workOrder.id);
            }
        },
        update: (key, values) => {
            return PostAPIPromise('WorkOrderCustomerFlights?itineraryId=' + key);
        }
    });

    setFlightStatus = async (e) => {
        //console.log(e);

        await this.flightsStore.update(e.row.data.flightItineraryId, {});

        this.dataGrid.instance().refresh();
    }

    canCancel = (options) => {
        return options.row.data && options.row.data.itineraryStatus != 'CANCELLED';
    }

    canActivate = (options) => {
        return options.row.data && options.row.data.itineraryStatus != 'ACTIVE';
    }

    isAssignFlightVisible = (options) => {
        return options.row.data && !options.row.data.customerID && this.state.userIsSysAdmin;
    }

    isUnlinkFlightVisible = (options) => {
        return options.row.data && options.row.data.customerID && this.state.userIsSysAdmin;
    }

    isMoveFlightVisible = (options) => {
        return options.row.data && !options.row.data.customerID && this.state.userIsSysAdmin;
    }

    onRowPrepared = (e) => {
        if (e.rowType == 'data' && e.data.itineraryStatus == 'CANCELLED') {
            e.rowElement.className = e.rowElement.className + ' cancelledRow';
        }
    }

    moveFlight = async (e) => {
        console.log(e);

        const employeesData = await this.FetchAPI('WorkOrderResourcesEmployeeList/' + this.props.workOrder.id);
        const inactiveEmployeesData = await this.FetchAPI('WorkOrderResourcesEmployeeList/GetInactiveEmployees/' + this.props.workOrder.id);

        this.setState({
            isMovingFlight: true,
            itineraryItemId: e.row.data.customerItineraryId,
            eligibleFlightEmployees: employeesData.concat(inactiveEmployeesData)
        });
    }

    moveWorkOrderFlight = async (e) => {
        //console.log(e);

        this.setState({
            isMovingWorkOrder: true,
            itineraryItemId: e.row.data.customerItineraryId
        });
    }

    correctFlight = async (e) => {
        // console.log(e);

        const customersData = await this.FetchAPI('WorkOrderResourcesCustomers/GetFlattenedCustomerList/' + this.props.workOrder.id);
        const inactiveCustomersData = await this.FetchAPI('WorkOrderResourcesCustomers/GetFlattenedInactiveCustomerList/' + this.props.workOrder.id);

        this.setState({
            isCorrectingFlight: true,
            itineraryItemId: e.row.data.customerItineraryId,
            eligibleFlightCustomers: customersData.concat(inactiveCustomersData)
        });
    }

    mergeFlight = async (e) => {
        console.log(e);

        const flightsData = await this.FetchAPI('WorkOrderCustomerFlights/' + this.props.workOrder.id);

        this.setState({
            isMergingCustomerFlight: true,
            overwritingMergeItem: [e.row.data],
            eligibleMergeFlights: flightsData
        });
    }

    unlinkFlight = async (e) => {
        // console.log(e);

        var result = await this.PostAPI('WorkOrderCustomerFlights/UnlinkFlight?itineraryId=' + e.row.data.customerItineraryId);

        if (result.status == -1) {
            alert(result.message);
        }
        else {
            this.dataGrid.instance().refresh();
            alert('Flight unlink Successful.');
        }
    }








    hideMoveDialog = () => {
        this.setState({
            isMovingFlight: false,
            itineraryItemId: null,
            eligibleFlightEmployees: []
        });
    }

    hideMoveWorkOrderDialog = () => {
        this.setState({
            isMovingWorkOrder: false,

            destinationWorkOrder: {
                workOrderNumber: ''
            }
        });
    }

    hideCorrectDialog = () => {
        this.setState({
            isCorrectingFlight: false,
            itineraryItemId: null,
            eligibleFlightCustomers: []
        });
    }

    hideMergeCustomerDialog = () => {
        this.setState({
            isMergingCustomerFlight: false,
            overwritingMergeItem: [],
            eligibleMergeFlights: []
        });
    }

    saveCustomerFlightMerge = async (forceMerge) => {
        this.mergeCustomerFlightsGrid.instance().getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select a Flight to Merge Flight to.");
            }
            else {
                var rowToMerge = rowData[0];

                //console.log(rowToMove);

                var param = {
                    sourceItineraryItemId: this.state.overwritingMergeItem[0].customerItineraryId,
                    destinationItineraryItemId: rowToMerge.customerItineraryId,
                    isForcing: forceMerge
                }

                var moveResult = await this.PostAPI('WorkOrderCustomerFlights/MergeCustomerFlight', param);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideMergeCustomerDialog();
                    this.dataGrid.instance().refresh();
                }
            }
        })
    }

    saveFlightCorrection = async () => {
        this.correctFlightsDataGrid.instance().getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select an Employee to correct Flight to.");
            }
            else {
                var rowToMove = rowData[0];

                console.log(rowToMove);

                var param = {
                    customerId: rowToMove.customerID,
                    itineraryItemId: this.state.itineraryItemId
                }

                var moveResult = await this.PostAPI('WorkOrderCustomerFlights/CorrectCustomerFlight', param);

                //console.log(moveResult);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideCorrectDialog();
                    this.dataGrid.instance().refresh();
                    alert('Flight correction Successful.');
                }
            }
        })
    }

    saveFlightMove = async () => {
        this.moveFlightsDataGrid.instance().getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select an Employee to move Flight to.");
            }
            else {
                var rowToMove = rowData[0];

                //console.log(rowToMove);

                var param = {
                    employeeId: rowToMove.employeeId,
                    itineraryItemId: this.state.itineraryItemId
                }

                var moveResult = await this.PostAPI('WorkOrderEmployeeFlights/MoveCustomerFlight', param);

                //console.log(moveResult);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideMoveDialog();
                    this.dataGrid.instance().refresh();
                    alert('Split Placement Successful. Reload the page to view the updated data.');
                }
            }
        })
    }

    saveFlightWorkOrderMove = async () => {

        var result = this.moveWorkOrderForm.instance().validate();

        if (result.isValid) {

            var param = {
                itineraryItemId: this.state.itineraryItemId,
                destinationWorkOrderNumber: this.state.destinationWorkOrder.workOrderNumber
            };

            var moveResult = await this.PostAPI('WorkOrderCustomerFlights/MoveWorkOrderFlight', param);

            console.log(moveResult);

            if (moveResult.status == -1) {
                alert(moveResult.message);
            }
            else {
                this.hideMoveWorkOrderDialog();
                this.dataGrid.instance().refresh();
            }
        }
    }

    componentIsReadOnly = () => {
        return this.IsReadOnly() || this.props.workOrder.statusID > 1;
    }

    // TODO: Customer matching still not quite right
    render() {
        return (
            <div style={{ margin: "10px" }}>
                <DataGrid ref={ref => this.dataGrid = ref} dataSource={this.flightsStore} showBorders={true} allowColumnResizing={true} onRowPrepared={this.onRowPrepared} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"customerFlights" + this.workOrderName} />
                    <Editing mode="row" allowUpdating={this.allowUpdating} />
                    <GroupPanel visible={true} />

                    <Column caption="Customer Name" dataField="travelerName" groupIndex={0} />
                    <Column caption="Booking Status" dataField="bookingStatus" />
                    <Column caption="Flight Number" dataField="flightNumber" />
                    <Column caption="Departure Airport Code" dataField="departureAirportCode" />
                    <Column caption="Departure Time" dataField="departureLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Arrival Airport Code" dataField="arrivalAirportCode" />
                    <Column caption="Arrival Time" dataField="arrivalLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Itinerary Status" dataField="itineraryStatus" />
                    <Column visible={!this.componentIsReadOnly()} type="buttons">
                        <GridButton
                            text="Move"
                            hint="Move"
                            onClick={this.moveFlight}
                            visible={this.isMoveFlightVisible}
                        />
                        <GridButton
                            text="Move Work Order"
                            hint="Move Work Order"
                            onClick={this.moveWorkOrderFlight}
                            visible={this.isMoveFlightVisible}
                        />
                        <GridButton
                            text="Correct"
                            hint="Correct"
                            onClick={this.correctFlight}
                            visible={this.isAssignFlightVisible}
                        />
                        <GridButton
                            text="Unlink"
                            hint="Unlink"
                            onClick={this.unlinkFlight}
                            visible={this.isUnlinkFlightVisible}
                        />
                        <GridButton
                            text="Merge"
                            hint="Merge"
                            onClick={this.mergeFlight}
                        />
                        <GridButton
                            text="Cancel"
                            hint="Cancel"
                            onClick={this.setFlightStatus}
                            visible={this.canCancel}
                        />
                        <GridButton
                            text="Make Active"
                            hint="Make Active"
                            onClick={this.setFlightStatus}
                            visible={this.canActivate}
                        />
                    </Column>

                    <MasterDetail enabled={true} component={FlightDetail} />
                </DataGrid>

                <Popup visible={this.state.isMovingFlight} onHiding={this.hideMoveDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                    <p>Select the Employee to apply this Flight to</p>
                    <br />
                    <DataGrid ref={ref => this.moveFlightsDataGrid = ref} dataSource={this.state.eligibleFlightEmployees} showBorders={true} allowColumnResizing={true}>
                        <FilterRow visible={true} />
                        <Selection mode="single" selectAllMode="allPages" deferred={true} />

                        <Column caption="Employee No" dataField="employeeNo" />
                        <Column caption="First Name" dataField="firstName" />
                        <Column caption="Middle Name" dataField="middleName" />
                        <Column caption="Last Name" dataField="lastName" />
                        <Column caption="Gender" dataField="sex" />
                        <Column caption="Office" dataField="office" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveFlightMove.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideMoveDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isCorrectingFlight} onHiding={this.hideCorrectDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Select the Customer to correct this Flight to</p>
                        <br />
                        <DataGrid ref={ref => this.correctFlightsDataGrid = ref} dataSource={this.state.eligibleFlightCustomers} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="A#/Subject ID" dataField="comboIDDisplay" />
                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="Age" dataField="age" />
                            <Column caption="Gender" dataField="gender" />
                            <Column caption="Nationality" dataField="nationality" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveFlightCorrection.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideCorrectDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isMovingWorkOrder} onHiding={this.hideMoveWorkOrderDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Enter the Work Order to move this Flight to</p>
                        <br />
                        <Form ref={ref => this.moveWorkOrderForm = ref} formData={this.state.destinationWorkOrder}>
                            <Item editorType="dxTextBox" dataField="workOrderNumber">
                                <RequiredRule />
                            </Item>
                        </Form>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveFlightWorkOrderMove.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideMoveWorkOrderDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isMergingCustomerFlight} onHiding={this.hideMergeCustomerDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={1000} height={675}>
                    <ScrollView height={575}>
                        <p>Select the Flight to Merge this Flight on top of</p>
                        <DataGrid dataSource={this.state.overwritingMergeItem} showBorders={true} allowColumnResizing={true}>

                            <Column caption="Customer Name" dataField="travelerName" />
                            <Column caption="Booking Status" dataField="bookingStatus" />
                            <Column caption="Confirmation #" dataField="confirmationNumber" />
                            <Column caption="Flight Number" dataField="flightNumber" />
                            <Column caption="Departure Airport Code" dataField="departureAirportCode" />
                            <Column caption="Departure Time" dataField="departureLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Arrival Airport Code" dataField="arrivalAirportCode" />
                            <Column caption="Arrival Time" dataField="arrivalLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Service Class" dataField="classOfService" />
                            <Column caption="Itinerary Status" dataField="itineraryStatus" />
                        </DataGrid>
                        <br />
                        <DataGrid ref={ref => this.mergeCustomerFlightsGrid = ref} dataSource={this.state.eligibleMergeFlights} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="Customer Name" dataField="travelerName" groupIndex={0} />
                            <Column caption="Booking Status" dataField="bookingStatus" />
                            <Column caption="Confirmation #" dataField="confirmationNumber" />
                            <Column caption="Flight Number" dataField="flightNumber" />
                            <Column caption="Departure Airport Code" dataField="departureAirportCode" />
                            <Column caption="Departure Time" dataField="departureLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Arrival Airport Code" dataField="arrivalAirportCode" />
                            <Column caption="Arrival Time" dataField="arrivalLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Service Class" dataField="classOfService" />
                            <Column caption="Itinerary Status" dataField="itineraryStatus" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveCustomerFlightMerge.bind(this, false)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideMergeCustomerDialog} />
                        &nbsp;&nbsp;
                        <Button text="Force" onClick={this.saveCustomerFlightMerge.bind(this, true)} />
                    </div>
                </Popup>
            </div>
        );
    }
}

class FlightDetail extends React.Component {
    constructor(props) {
        //console.log('Detail Props', props);
        super(props);
        this.flightDetails = props.data.row.data.travelerName + "_" + props.data.row.data.flightNumber;
        this.dataSource = [props.data.row.data];
    }

    render() {
        return (
            <DataGrid dataSource={this.dataSource} showBorders={true} allowColumnResizing={true} onExporting={this.onExporting}>
                <Export enabled={true} fileName={"flightDetails_" + this.flightDetails} />
                <Column caption="Record Locator" dataField="recordLocator" />
                <Column caption="Confirmation Number" dataField="confirmationNumber" />
                <Column caption="Service Class" dataField="classOfService" />
                <Column caption="Marketing Carrier" dataField="marketingCarrier" />
                <Column caption="Operating Carrier" dataField="operatingCarrier" />
                <Column caption="Departure Airport Name" dataField="departureAirportName" />
                <Column caption="Departure Terminal" dataField="departureAirportTerminal" />
                <Column caption="Arrival Airport Name" dataField="arrivalAirportName" />
                <Column caption="Arrival Terminal" dataField="arrivalAirportTerminal" />
            </DataGrid>
        );
    }
}